import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import React from "react";

const Bold = ({ children }) => <strong>{children}</strong>;
const Italic = ({ children }) => <em>{children}</em>;
const Underline = ({ children }) => <u>{children}</u>;
const Text = ({ children }) => <p>{children}</p>;

export const richTextOptions = {
	renderMark: {
		[MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
		[MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
		[MARKS.UNDERLINE]: (text) => <Underline>{text}</Underline>,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
	},
};
